"use client";
import HeaderAuth from "@/components/header-auth";
import React from 'react';
import { ShoppingBag, CreditCard, Rocket, Lock, Menu } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/cards";
import Link from "next/link";
import Footer from "./domain/[domain]/components/footer";
import MainNav from "@/components/ui/mainnav";
import ContactUs from "@/components/contactus";

export default function HomePage() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // links props for footer
  const links = [
    { text: 'Privacy Policy', href: '/legals/privacy_policy' },
    { text: 'Terms of Use', href: '/legals/terms_of_use' },
    {text: "Store Terms of Use", href: "/legals/store_owner_policies"},
    {text: "About", href: "/about"}
  ];

  const navLinks = [
    { text: 'About', href: '/about' },
    { text: 'Features', href: '/#features' },
    { text: 'Pricing', href: '/#pricing' },
    { text: 'Sign Up', href: '/sign-up' },
  ];

  return (
    <div className="min-h-screen bg-white w-full">
      {/* Navigation Bar */}
      <MainNav navLinks={navLinks} /> 

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6">Launch Your Online Store Without Upfront Costs</h1>
            <p className="text-xl mb-8">Start selling today. Pay only when you make money.</p>
            <Button asChild className="bg-white text-blue-600 hover:bg-gray-100 text-lg px-8 py-6">
              <Link href="/sign-up">Start Free Today</Link>
            </Button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-20 bg-gray-50" id="features">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Us?</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <Card className="p-6">
              <CardContent className="space-y-4 pt-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <ShoppingBag className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold">Free to Start</h3>
                <p className="text-gray-600">No setup fees, no monthly charges. Create your store at zero cost.</p>
              </CardContent>
            </Card>

            <Card className="p-6">
              <CardContent className="space-y-4 pt-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <CreditCard className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold">Pay Per Sale</h3>
                <p className="text-gray-600">Only pay a small fee when you make a successful sale.</p>
              </CardContent>
            </Card>

            <Card className="p-6">
              <CardContent className="space-y-4 pt-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <Rocket className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold">Quick Setup</h3>
                <p className="text-gray-600">Launch your store in minutes with our intuitive platform.</p>
              </CardContent>
            </Card>

            <Card className="p-6">
              <CardContent className="space-y-4 pt-4">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                  <Lock className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold">Secure Platform</h3>
                <p className="text-gray-600">Enterprise-grade security for you and your customers.</p>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="py-20" id="pricing">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Simple, Transaction-Based Pricing</h2>
            <div className="bg-white rounded-lg shadow-lg p-8 mt-8">
              <div className="text-center">
                <p className="text-6xl font-bold text-blue-600 mb-4">5.0%</p>
                <p className="text-xl text-gray-600 mb-6">per successful transaction</p>
                <ul className="text-left space-y-4 mb-8">
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span> No monthly fees
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span> No setup costs
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span> Unlimited products
                  </li>
                  <li className="flex items-center">
                    <span className="text-green-500 mr-2">✓</span> 24/7 support
                  </li>
                </ul>
                <Button asChild className="bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6">
                  <Link href="/sign-up">Create Free Store</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50 py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Start Selling Online?</h2>
          <p className="text-xl text-gray-600 mb-8">Join thousands of successful businesses on our platform.</p>
          <Button asChild className="bg-blue-600 hover:bg-blue-700 text-lg px-8 py-6">
            <Link href="/sign-up">Get Started For Free</Link>
          </Button>
        </div>
      </div>
.
      {/* Contact Section */}
      <ContactUs email="support@yourfirststore.com" />

      {/* Footer Section */}
      <Footer companyName="YourFirstStore" settings={undefined} links={links} />
    </div>
  );
}